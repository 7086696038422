import React, { useEffect } from "react";
import { Outlet, Routes, Route, Link } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../helpers";

export function AuthLayout() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
      >
        {/* Aside */}
        <div className="my-5 d-flex flex-column flex-lg-row-auto bg-coinbase w-lg-600px pt-15 pt-lg-0">
          {/* Top */}
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            {/* begin::Aside Logo */}
            <Link to="/" className="mb-6">
              <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-default.png")} className="h-100px" />
            </Link>
            {/* end::Aside Logo */}

            {/* begin::Aside Subtitle */}
            <h3 className="fw-lighter fs-1x text-black lh-lg">
              A New Way to Invest
            </h3>
            {/* end::Aside Subtitle */}
          </div>

          {/* Bottom */}
          <div
            className="my-5 d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-350px"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/user-welcome.png")})`,
            }}>

          </div>
        </div>

        {/* Content */}
        <div className="my-5 login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
            <Outlet />
          </div>

          <div className="my-5 d-flex justify-content-lg-start justify-content-center align-items-center py-7 py-lg-0">
            <span className="my-5 mx-4 text-nova fw-bolder fs-4 cursor-pointer">
              Terms
            </span>
            <span className="my-5 mx-4 text-nova ms-10 fw-bolder fs-4 cursor-pointer">
              Contact Us
            </span>
          </div>
          
        </div>
      </div>
    </div>
  );
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };

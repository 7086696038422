import React, {useState, useEffect} from 'react'
import { useReadBalanceQuery } from '../../../../generated/schema'



export const CurrentBalance: React.FC = () => {
  const [balance, setBalance] = useState(0.0)
  const [exchange, setExchange] = useState('binance')

  const { data } = useReadBalanceQuery({
    variables: {exchange: exchange}
  })

  useEffect(() => {
    if(data && data?.readUserBalance) {
      setBalance(Number(data?.readUserBalance.balance.toFixed(2)))
    }
  }, [data, exchange])

  return (
    <div>
        <div className='mb-2'>
        <select value={exchange} onChange={(e) => setExchange(e.target.value)}>
            <option value="binance">Binance</option>
            <option value="bybit">Bybit</option>
            <option value="okx">OKX</option>
            <option value="kucoin">Kucoin</option>
        </select>
        <div className='mt-2'>Balance USDT : {balance} $</div>

       </div>
    </div>
  )
}

export default CurrentBalance
import React, { ReactNode } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../helpers";

export function AsideMenuMain() {
  return (
    <div>
      <div className="row my-4">
        <div className=" d-flex flex-column justify-content-end align-items-center ">
          <AsideMenuItem to="/overview" title="Overview" />
          <AsideMenuItem to="/account" title="Account" />
          <AsideMenuItem to="/academy" title="Academy" />
        </div>
      </div>

      <div className="row my-3">
        <div className=" d-flex flex-column justify-content-end align-items-center ">
          <AsideMenuItem to="/strategies" title="Strategies" />
          <AsideMenuItem to="/builder" title="Builder" />
        </div>
      </div>

      <div className="row my-3">
        <div className=" d-flex flex-column justify-content-center align-items-center ">
          <AsideMenuItem to="/subscription" title="Subscription" />
          <AsideMenuItem to="/support" title="Support" />
        </div>
      </div>
    </div>
  );
}


type Props = {
  children?: ReactNode;
  to: string;
  title: string;
  hasBullet?: boolean;
  free?: boolean;
  exclusive?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({to, title}) => {
  const { pathname } = useLocation();
  return (
    <div className={clsx("menu-item", { here: checkIsActive(pathname, to),})}>
      <Link className="menu-link text-decoration-none" to={to}>
        <span className="menu-title">{title}</span>
      </Link>
    </div>
  );
};


import React, { useEffect, useState } from 'react';

interface EventData {
  date: string;
  execution_id: string;
  state: string;
}

export const Overview: React.FC = () => {
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    const ws = new WebSocket(`ws://localhost:8080/bot`);

    ws.onmessage = (event) => {
      const eventData: EventData = JSON.parse(event.data);
      setEvents((prevEvents) => [...prevEvents, eventData]);
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div>
      <h1>Event Data</h1>
      <ul>
        {events.map((event, index) => (
          <li key={index}>
            Date: {event.date} | State: {event.state}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Overview;
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IThemeCSSClasses, useTheme } from "../../core";
import { Header } from "./Header";

type Props = {
  classes: IThemeCSSClasses;
};

const HeaderDesktopFixedTabletFixed: React.FC<Props> = ({ classes }) => {
  return (
    <div className={`${classes.header} bg-white py-4  sticky-top text-center`} >
      <Header />
    </div>
  );
};

export function HeaderWrapper() {
  const { classes } = useTheme();
  return (
    <>
      <HeaderDesktopFixedTabletFixed classes={classes} />
    </>
  );
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Backtest */
export type Backtest = {
  __typename?: 'Backtest';
  _id: Scalars['ObjectId'];
  candle: Scalars['String'];
  end: Scalars['Float'];
  exchange: Scalars['String'];
  fees: Scalars['Float'];
  geometric_sizes: Scalars['Boolean'];
  historical_window: Scalars['Int'];
  leverage: Scalars['Int'];
  list_pair: Array<Scalars['String']>;
  max_holding: Scalars['Int'];
  max_pos: Scalars['Int'];
  parameters: Array<StrategyParameters>;
  quote_asset: Scalars['String'];
  start: Scalars['Float'];
  start_bk: Scalars['Float'];
  statistics: Statistics;
  strategy: Strategy;
  version: Scalars['Int'];
};

export type Balance = {
  __typename?: 'Balance';
  balance: Scalars['Float'];
  exchange: Scalars['String'];
};

/** Virtual bot that runs our algorithms */
export type Bot = {
  __typename?: 'Bot';
  _id: Scalars['ObjectId'];
  executions?: Maybe<Array<Execution>>;
  name: Scalars['String'];
  telegramToken: Scalars['String'];
  totalProfit: Scalars['Float'];
  user: User;
};

export type CreateBacktestInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  candle: Scalars['String'];
  end: Scalars['Float'];
  exchange: Scalars['String'];
  fees: Scalars['Float'];
  geometric_sizes: Scalars['Boolean'];
  historical_window: Scalars['Int'];
  leverage: Scalars['Int'];
  list_pair: Array<Scalars['String']>;
  max_holding: Scalars['Int'];
  max_pos: Scalars['Int'];
  parameters: Array<StrategyParametersInput>;
  quote_asset: Scalars['String'];
  start: Scalars['Float'];
  start_bk: Scalars['Float'];
};

export type CreateBotInput = {
  name: Scalars['String'];
  telegramToken: Scalars['String'];
};

export type CreateStrategyInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ObjectId']>;
  name: Scalars['String'];
};

export type CreateUserInput = {
  bot_limits?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ObjectId']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  profit?: InputMaybe<Scalars['Float']>;
};

export type CurrentBuildsInput = {
  currentBuilds: Scalars['Int'];
  id: Scalars['ObjectId'];
};

export type EnterPositionInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  currentSize: Scalars['Float'];
  entryFees: Scalars['Float'];
  entryPrice: Scalars['Float'];
  entryTime: Scalars['Int'];
  exchange: Scalars['String'];
  execution_id: Scalars['String'];
  originalSize: Scalars['Float'];
  pair: Scalars['String'];
  positionStatus: Scalars['String'];
  positionType: Scalars['String'];
  slId: Scalars['String'];
  slPrice: Scalars['Float'];
  tpId: Scalars['String'];
  tpPrice: Scalars['Float'];
  userId: Scalars['String'];
};

/** Exchange Model */
export type Exchange = {
  __typename?: 'Exchange';
  _id: Scalars['ObjectId'];
  historicalBacktest: Scalars['Boolean'];
  name: Scalars['String'];
  productionReady: Scalars['Boolean'];
  quoteAssets: Array<Scalars['String']>;
  tradeablePairs: Array<Scalars['String']>;
};

export type ExchangeInput = {
  historicalBacktest: Scalars['Boolean'];
  name: Scalars['String'];
  productionReady: Scalars['Boolean'];
  quoteAssets: Array<Scalars['String']>;
  tradeablePairs: Array<Scalars['String']>;
};

/** Executions */
export type Execution = {
  __typename?: 'Execution';
  _id: Scalars['ObjectId'];
  backtest: Backtest;
  bankRoll: Scalars['Float'];
  bot: Bot;
  canLong: Scalars['Boolean'];
  canShort: Scalars['Boolean'];
  end: Scalars['Timestamp'];
  isReal: Scalars['Boolean'];
  maxDown: Scalars['Float'];
  start: Scalars['Timestamp'];
  status: Scalars['String'];
};

/** Parameters */
export type ExecutionsInput = {
  bankRoll: Scalars['Float'];
  canLong: Scalars['Boolean'];
  canShort: Scalars['Boolean'];
  isReal: Scalars['Boolean'];
  maxDown: Scalars['Float'];
};

export type ExitPositionInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  currentSize: Scalars['Float'];
  exitFees: Scalars['Float'];
  exitPrice: Scalars['Float'];
  exitTime: Scalars['Int'];
  exitType: Scalars['String'];
  positionStatus: Scalars['String'];
  quantityExited: Scalars['Float'];
  realizedPnl: Scalars['Float'];
  totalFees: Scalars['Float'];
};

/** Godzilla Answer */
export type GodzillaAnswer = {
  __typename?: 'GodzillaAnswer';
  answer: Scalars['String'];
};

/** User exchange API key model */
export type Key = {
  __typename?: 'Key';
  _id: Scalars['ObjectId'];
  exchange: Scalars['String'];
  name: Scalars['String'];
  passphrase: Scalars['String'];
  privateKey: Scalars['String'];
  publicKey: Scalars['String'];
  user: User;
};

export type KeyInput = {
  exchange: Scalars['String'];
  name: Scalars['String'];
  passphrase: Scalars['String'];
  privateKey: Scalars['String'];
  publicKey: Scalars['String'];
};

export type KeyResponse = {
  __typename?: 'KeyResponse';
  _id?: Maybe<Scalars['ObjectId']>;
  exchange?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  passphrase?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export type LastBuildInput = {
  id: Scalars['ObjectId'];
  lastBuild: Scalars['Timestamp'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addModel: Strategy;
  backtestStrategy: GodzillaAnswer;
  createBacktest: Backtest;
  createBot: Bot;
  createExchange: Exchange;
  createExecution: Execution;
  createKey: Key;
  createPosition: Position;
  createProspect: Prospect;
  createStrategy: Strategy;
  createStrategyStatistics: Statistics;
  createUser: User;
  deleteBacktest: Scalars['Boolean'];
  deleteBot: Bot;
  deleteExchange: Scalars['Boolean'];
  deleteExecution: Scalars['Boolean'];
  deleteKey: Scalars['Boolean'];
  deletePosition: Position;
  deleteStrategy: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  exitPosition: Position;
  login: UserResponse;
  paymentIntentStripe: StripeAnswer;
  startBot: GodzillaAnswer;
  startPaperBot: GodzillaAnswer;
  stopBot: GodzillaAnswer;
  stopPaperBot: GodzillaAnswer;
  updateCurrentBuilds: User;
  updateExchange: Exchange;
  updateLastBuild: User;
  updateRole: User;
  updateUser: User;
};


export type MutationAddModelArgs = {
  strategyFile: Scalars['Upload'];
  strategy_id: Scalars['String'];
};


export type MutationBacktestStrategyArgs = {
  backtest_id: Scalars['String'];
};


export type MutationCreateBacktestArgs = {
  input: CreateBacktestInput;
  strategy_id: Scalars['ObjectId'];
};


export type MutationCreateBotArgs = {
  input: CreateBotInput;
};


export type MutationCreateExchangeArgs = {
  input: ExchangeInput;
};


export type MutationCreateExecutionArgs = {
  backtest_id: Scalars['ObjectId'];
  bot_id: Scalars['ObjectId'];
  input: ExecutionsInput;
};


export type MutationCreateKeyArgs = {
  input: KeyInput;
};


export type MutationCreatePositionArgs = {
  input: EnterPositionInput;
};


export type MutationCreateProspectArgs = {
  input: ProspectInput;
};


export type MutationCreateStrategyArgs = {
  file: Scalars['Upload'];
  input: CreateStrategyInput;
};


export type MutationCreateStrategyStatisticsArgs = {
  backtestId: Scalars['String'];
  input: StatisticsInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteBacktestArgs = {
  backtest_id: Scalars['ObjectId'];
};


export type MutationDeleteBotArgs = {
  input: Scalars['ObjectId'];
};


export type MutationDeleteExchangeArgs = {
  exchangeId: Scalars['ObjectId'];
};


export type MutationDeleteExecutionArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteKeyArgs = {
  keyId: Scalars['ObjectId'];
};


export type MutationDeletePositionArgs = {
  input: Scalars['ObjectId'];
};


export type MutationDeleteStrategyArgs = {
  strategy_id: Scalars['ObjectId'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ObjectId'];
};


export type MutationExitPositionArgs = {
  input: ExitPositionInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPaymentIntentStripeArgs = {
  plan: Scalars['String'];
  sales: Scalars['Boolean'];
};


export type MutationStartBotArgs = {
  bot_id: Scalars['String'];
};


export type MutationStartPaperBotArgs = {
  bot_id: Scalars['String'];
};


export type MutationStopBotArgs = {
  bot_id: Scalars['String'];
};


export type MutationStopPaperBotArgs = {
  bot_id: Scalars['String'];
};


export type MutationUpdateCurrentBuildsArgs = {
  input: CurrentBuildsInput;
};


export type MutationUpdateExchangeArgs = {
  input: ExchangeInput;
};


export type MutationUpdateLastBuildArgs = {
  input: LastBuildInput;
};


export type MutationUpdateRoleArgs = {
  input: RoleInput;
};


export type MutationUpdateUserArgs = {
  input: CreateUserInput;
};

/** OpenAI Answer */
export type OpenAi = {
  __typename?: 'OpenAI';
  answer?: Maybe<Scalars['String']>;
};

/** Position model */
export type Position = {
  __typename?: 'Position';
  _id?: Maybe<Scalars['ObjectId']>;
  currentSize: Scalars['Float'];
  entryFees: Scalars['Float'];
  entryPrice: Scalars['Float'];
  entryTime: Scalars['Int'];
  exchange: Scalars['String'];
  execution_id: Scalars['String'];
  exitFees?: Maybe<Scalars['Float']>;
  exitPrice?: Maybe<Scalars['Float']>;
  exitTime?: Maybe<Scalars['Int']>;
  exitType?: Maybe<Scalars['String']>;
  originalSize: Scalars['Float'];
  pair: Scalars['String'];
  positionStatus: Scalars['String'];
  positionType: Scalars['String'];
  quantityExited?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  slId: Array<Scalars['String']>;
  slPrice: Scalars['Float'];
  totalFees?: Maybe<Scalars['Float']>;
  tpId: Scalars['String'];
  tpPrice: Scalars['Float'];
  userId: Scalars['String'];
};

/** Prospect Model */
export type Prospect = {
  __typename?: 'Prospect';
  _id: Scalars['ObjectId'];
  algoTrader: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ProspectInput = {
  algoTrader: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  backtestById: Backtest;
  backtestByStrategy: Array<Backtest>;
  botById: Bot;
  botsByUser: Array<Bot>;
  currentUser?: Maybe<User>;
  exchange: Exchange;
  exchanges: Array<Exchange>;
  executionById: Execution;
  getAnswer: OpenAi;
  keys: Array<Key>;
  keysByExchange: Array<KeyResponse>;
  pingGodzilla: GodzillaAnswer;
  positionByExecution: Array<Position>;
  readStatistics?: Maybe<Statistics>;
  readUserBalance?: Maybe<Balance>;
  strategies: Array<Strategy>;
  strategyById?: Maybe<Strategy>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryBacktestByIdArgs = {
  backtest_id: Scalars['String'];
};


export type QueryBacktestByStrategyArgs = {
  strategy_id: Scalars['String'];
};


export type QueryBotByIdArgs = {
  bot_id: Scalars['ObjectId'];
};


export type QueryExchangeArgs = {
  input: Scalars['String'];
};


export type QueryExecutionByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetAnswerArgs = {
  input: Scalars['String'];
};


export type QueryKeysByExchangeArgs = {
  exchange: Scalars['String'];
};


export type QueryPositionByExecutionArgs = {
  execution_id: Scalars['String'];
};


export type QueryReadStatisticsArgs = {
  backtest_id: Scalars['ObjectId'];
};


export type QueryReadUserBalanceArgs = {
  exchange: Scalars['String'];
};


export type QueryStrategyByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUserArgs = {
  userId: Scalars['ObjectId'];
};

export type RoleInput = {
  id: Scalars['ObjectId'];
  roles: Array<Scalars['String']>;
};

/** Statistics */
export type Statistics = {
  __typename?: 'Statistics';
  _id: Scalars['ObjectId'];
  annualized_std_deviation: Scalars['Float'];
  avg_holding_duration_h: Scalars['Float'];
  avg_long_profit_prct: Scalars['Float'];
  avg_position_size: Scalars['Float'];
  avg_profit_per_loss_trade: Scalars['Float'];
  avg_profit_per_loss_trade_prct: Scalars['Float'];
  avg_profit_per_trade: Scalars['Float'];
  avg_profit_per_trade_prct: Scalars['Float'];
  avg_profit_per_win_trade: Scalars['Float'];
  avg_profit_per_win_trade_prct: Scalars['Float'];
  avg_short_profit_prct: Scalars['Float'];
  backtest: Backtest;
  best_day_profit: Scalars['Float'];
  best_pair_perf: Scalars['String'];
  best_pair_return: Scalars['Float'];
  best_trade: Scalars['Float'];
  cumulative_fees_paid: Scalars['Float'];
  downside_volatility: Scalars['Float'];
  geometric_return: Scalars['Float'];
  max_drawdown: Scalars['Float'];
  max_drawdown_end: Scalars['String'];
  max_drawdown_start: Scalars['String'];
  max_nb_days_underwater: Scalars['Float'];
  nb_long_positions: Scalars['Float'];
  nb_losing_trades: Scalars['Float'];
  nb_short_positions: Scalars['Float'];
  nb_winning_trades: Scalars['Float'];
  open_date: Array<Scalars['String']>;
  pair_with_less_positions: Scalars['String'];
  pair_with_most_positions: Scalars['String'];
  prc_exit_signals: Scalars['Float'];
  prc_max_holding: Scalars['Float'];
  prc_sl: Scalars['Float'];
  prc_tp: Scalars['Float'];
  prc_winning_trade: Scalars['Float'];
  profit_long: Array<Scalars['Float']>;
  profit_short: Array<Scalars['Float']>;
  realized_profit: Scalars['Float'];
  sharp_ratio: Scalars['Float'];
  sortino_ratio: Scalars['Float'];
  total_nb_trades: Scalars['Float'];
  worse_day_loss: Scalars['Float'];
  worse_trade: Scalars['Float'];
  worst_pair_perf: Scalars['String'];
  worst_pair_return: Scalars['Float'];
};

export type StatisticsInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  annualized_std_deviation: Scalars['Float'];
  avg_holding_duration_h: Scalars['Float'];
  avg_long_profit_prct: Scalars['Float'];
  avg_position_size: Scalars['Float'];
  avg_profit_per_loss_trade: Scalars['Float'];
  avg_profit_per_loss_trade_prct: Scalars['Float'];
  avg_profit_per_trade: Scalars['Float'];
  avg_profit_per_trade_prct: Scalars['Float'];
  avg_profit_per_win_trade: Scalars['Float'];
  avg_profit_per_win_trade_prct: Scalars['Float'];
  avg_short_profit_prct: Scalars['Float'];
  best_day_profit: Scalars['Float'];
  best_pair_perf: Scalars['String'];
  best_pair_return: Scalars['Float'];
  best_trade: Scalars['Float'];
  cumulative_fees_paid: Scalars['Float'];
  downside_volatility: Scalars['Float'];
  geometric_return: Scalars['Float'];
  max_drawdown: Scalars['Float'];
  max_drawdown_end: Scalars['String'];
  max_drawdown_start: Scalars['String'];
  max_nb_days_underwater: Scalars['Float'];
  nb_long_positions: Scalars['Float'];
  nb_losing_trades: Scalars['Float'];
  nb_short_positions: Scalars['Float'];
  nb_winning_trades: Scalars['Float'];
  open_date: Array<Scalars['String']>;
  pair_with_less_positions: Scalars['String'];
  pair_with_most_positions: Scalars['String'];
  prc_exit_signals: Scalars['Float'];
  prc_max_holding: Scalars['Float'];
  prc_sl: Scalars['Float'];
  prc_tp: Scalars['Float'];
  prc_winning_trade: Scalars['Float'];
  profit_long: Array<Scalars['Float']>;
  profit_short: Array<Scalars['Float']>;
  realized_profit: Scalars['Float'];
  sharp_ratio: Scalars['Float'];
  sortino_ratio: Scalars['Float'];
  total_nb_trades: Scalars['Float'];
  worse_day_loss: Scalars['Float'];
  worse_trade: Scalars['Float'];
  worst_pair_perf: Scalars['String'];
  worst_pair_return: Scalars['Float'];
};

/** Strategy model */
export type Strategy = {
  __typename?: 'Strategy';
  _id: Scalars['ObjectId'];
  backtests?: Maybe<Array<Backtest>>;
  description: Scalars['String'];
  fileName: Scalars['String'];
  modelName: Scalars['String'];
  name: Scalars['String'];
  productionVersion: Scalars['Int'];
  user: User;
};

/** Parameters */
export type StrategyParameters = {
  __typename?: 'StrategyParameters';
  parameter: Scalars['String'];
  value: Scalars['Float'];
};

/** Parameters */
export type StrategyParametersInput = {
  parameter: Scalars['String'];
  value: Scalars['Float'];
};

/** Stripe Answer */
export type StripeAnswer = {
  __typename?: 'StripeAnswer';
  clientSecret: Scalars['String'];
};

/** User model in all Nova apps */
export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  bot_limits?: Maybe<Scalars['Int']>;
  current_builds?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  last_build?: Maybe<Scalars['Timestamp']>;
  maximum_builds?: Maybe<Scalars['Int']>;
  maximum_pairs?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  plan?: Maybe<Scalars['Int']>;
  profit?: Maybe<Scalars['Float']>;
  roles: Array<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type BacktestMutationVariables = Exact<{
  backtest_id: Scalars['String'];
}>;


export type BacktestMutation = { __typename?: 'Mutation', backtestStrategy: { __typename?: 'GodzillaAnswer', answer: string } };

export type CreateBacktestMutationVariables = Exact<{
  strategy_id: Scalars['ObjectId'];
  input: CreateBacktestInput;
}>;


export type CreateBacktestMutation = { __typename?: 'Mutation', createBacktest: { __typename?: 'Backtest', version: number } };

export type CreateExecutionMutationVariables = Exact<{
  input: ExecutionsInput;
  backtest_id: Scalars['ObjectId'];
  bot_id: Scalars['ObjectId'];
}>;


export type CreateExecutionMutation = { __typename?: 'Mutation', createExecution: { __typename?: 'Execution', _id: any } };

export type CreateStrategyMutationVariables = Exact<{
  file: Scalars['Upload'];
  input: CreateStrategyInput;
}>;


export type CreateStrategyMutation = { __typename?: 'Mutation', createStrategy: { __typename?: 'Strategy', name: string, _id: any } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', name: string, email: string } };

export type CreateUserKeyMutationVariables = Exact<{
  input: KeyInput;
}>;


export type CreateUserKeyMutation = { __typename?: 'Mutation', createKey: { __typename?: 'Key', _id: any, name: string, exchange: string, publicKey: string, privateKey: string, passphrase: string, user: { __typename?: 'User', _id: any, email: string } } };

export type BotsQueryVariables = Exact<{ [key: string]: never; }>;


export type BotsQuery = { __typename?: 'Query', botsByUser: Array<{ __typename?: 'Bot', _id: any, name: string }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', _id: any, email: string, name: string, profit?: number | null, language?: string | null, roles: Array<string>, plan?: number | null, maximum_pairs?: number | null, last_build?: any | null, maximum_builds?: number | null, current_builds?: number | null } | null };

export type StrategiesQueryVariables = Exact<{ [key: string]: never; }>;


export type StrategiesQuery = { __typename?: 'Query', strategies: Array<{ __typename?: 'Strategy', _id: any, name: string, description: string, productionVersion: number, backtests?: Array<{ __typename?: 'Backtest', _id: any, version: number, exchange: string }> | null }> };

export type GetExchangeKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExchangeKeyQuery = { __typename?: 'Query', keys: Array<{ __typename?: 'Key', _id: any, name: string, exchange: string, publicKey: string, privateKey: string, passphrase: string }> };

export type GetKeysByExchangeQueryVariables = Exact<{
  exchange: Scalars['String'];
}>;


export type GetKeysByExchangeQuery = { __typename?: 'Query', keysByExchange: Array<{ __typename?: 'KeyResponse', _id?: any | null, name: string, publicKey?: string | null, privateKey?: string | null, passphrase?: string | null }> };

export type GetOpenAiAnswerQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetOpenAiAnswerQuery = { __typename?: 'Query', getAnswer: { __typename?: 'OpenAI', answer?: string | null } };

export type GetStatisticsQueryVariables = Exact<{
  backtest_id: Scalars['ObjectId'];
}>;


export type GetStatisticsQuery = { __typename?: 'Query', readStatistics?: { __typename?: 'Statistics', _id: any, realized_profit: number, avg_profit_per_trade: number, avg_profit_per_trade_prct: number, avg_position_size: number, avg_profit_per_win_trade: number, avg_profit_per_win_trade_prct: number, avg_profit_per_loss_trade: number, avg_profit_per_loss_trade_prct: number, avg_long_profit_prct: number, avg_short_profit_prct: number, avg_holding_duration_h: number, best_trade: number, worse_trade: number, cumulative_fees_paid: number, nb_winning_trades: number, nb_losing_trades: number, total_nb_trades: number, prc_winning_trade: number, nb_long_positions: number, nb_short_positions: number, prc_exit_signals: number, prc_max_holding: number, prc_tp: number, prc_sl: number, best_day_profit: number, worse_day_loss: number, max_nb_days_underwater: number, best_pair_perf: string, best_pair_return: number, worst_pair_perf: string, worst_pair_return: number, pair_with_most_positions: string, pair_with_less_positions: string, geometric_return: number, annualized_std_deviation: number, sharp_ratio: number, downside_volatility: number, sortino_ratio: number, max_drawdown: number, max_drawdown_start: string, max_drawdown_end: string, open_date: Array<string>, profit_long: Array<number>, profit_short: Array<number> } | null };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', _id: any, name: string, email: string, roles: Array<string>, plan?: number | null, maximum_pairs?: number | null }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserResponse', token?: string | null, user?: { __typename?: 'User', name: string, roles: Array<string>, email: string, profit?: number | null, language?: string | null, plan?: number | null, maximum_pairs?: number | null } | null } };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename?: 'Query', pingGodzilla: { __typename?: 'GodzillaAnswer', answer: string } };

export type ReadBalanceQueryVariables = Exact<{
  exchange: Scalars['String'];
}>;


export type ReadBalanceQuery = { __typename?: 'Query', readUserBalance?: { __typename?: 'Balance', exchange: string, balance: number } | null };

export type ReadExchangesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadExchangesQuery = { __typename?: 'Query', exchanges: Array<{ __typename?: 'Exchange', _id: any, name: string, tradeablePairs: Array<string>, historicalBacktest: boolean, productionReady: boolean, quoteAssets: Array<string> }> };

export type ReadKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadKeysQuery = { __typename?: 'Query', keys: Array<{ __typename?: 'Key', _id: any, name: string, exchange: string, publicKey: string, privateKey: string, passphrase: string }> };

export type StartBotMutationVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type StartBotMutation = { __typename?: 'Mutation', startBot: { __typename?: 'GodzillaAnswer', answer: string } };

export type StartPaperBotMutationVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type StartPaperBotMutation = { __typename?: 'Mutation', startPaperBot: { __typename?: 'GodzillaAnswer', answer: string } };

export type StopBotMutationVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type StopBotMutation = { __typename?: 'Mutation', stopBot: { __typename?: 'GodzillaAnswer', answer: string } };

export type StopPaperBotMutationVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type StopPaperBotMutation = { __typename?: 'Mutation', stopPaperBot: { __typename?: 'GodzillaAnswer', answer: string } };

export type StripeMutationVariables = Exact<{
  plan: Scalars['String'];
  sales: Scalars['Boolean'];
}>;


export type StripeMutation = { __typename?: 'Mutation', paymentIntentStripe: { __typename?: 'StripeAnswer', clientSecret: string } };

export type UpdateCurrentBuildsMutationVariables = Exact<{
  input: CurrentBuildsInput;
}>;


export type UpdateCurrentBuildsMutation = { __typename?: 'Mutation', updateCurrentBuilds: { __typename?: 'User', current_builds?: number | null } };

export type UpdateLastBuildMutationVariables = Exact<{
  input: LastBuildInput;
}>;


export type UpdateLastBuildMutation = { __typename?: 'Mutation', updateLastBuild: { __typename?: 'User', last_build?: any | null } };


export const BacktestDocument = gql`
    mutation Backtest($backtest_id: String!) {
  backtestStrategy(backtest_id: $backtest_id) {
    answer
  }
}
    `;
export type BacktestMutationFn = Apollo.MutationFunction<BacktestMutation, BacktestMutationVariables>;

/**
 * __useBacktestMutation__
 *
 * To run a mutation, you first call `useBacktestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBacktestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backtestMutation, { data, loading, error }] = useBacktestMutation({
 *   variables: {
 *      backtest_id: // value for 'backtest_id'
 *   },
 * });
 */
export function useBacktestMutation(baseOptions?: Apollo.MutationHookOptions<BacktestMutation, BacktestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BacktestMutation, BacktestMutationVariables>(BacktestDocument, options);
      }
export type BacktestMutationHookResult = ReturnType<typeof useBacktestMutation>;
export type BacktestMutationResult = Apollo.MutationResult<BacktestMutation>;
export type BacktestMutationOptions = Apollo.BaseMutationOptions<BacktestMutation, BacktestMutationVariables>;
export const CreateBacktestDocument = gql`
    mutation CreateBacktest($strategy_id: ObjectId!, $input: CreateBacktestInput!) {
  createBacktest(strategy_id: $strategy_id, input: $input) {
    version
  }
}
    `;
export type CreateBacktestMutationFn = Apollo.MutationFunction<CreateBacktestMutation, CreateBacktestMutationVariables>;

/**
 * __useCreateBacktestMutation__
 *
 * To run a mutation, you first call `useCreateBacktestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBacktestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBacktestMutation, { data, loading, error }] = useCreateBacktestMutation({
 *   variables: {
 *      strategy_id: // value for 'strategy_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBacktestMutation(baseOptions?: Apollo.MutationHookOptions<CreateBacktestMutation, CreateBacktestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBacktestMutation, CreateBacktestMutationVariables>(CreateBacktestDocument, options);
      }
export type CreateBacktestMutationHookResult = ReturnType<typeof useCreateBacktestMutation>;
export type CreateBacktestMutationResult = Apollo.MutationResult<CreateBacktestMutation>;
export type CreateBacktestMutationOptions = Apollo.BaseMutationOptions<CreateBacktestMutation, CreateBacktestMutationVariables>;
export const CreateExecutionDocument = gql`
    mutation createExecution($input: ExecutionsInput!, $backtest_id: ObjectId!, $bot_id: ObjectId!) {
  createExecution(input: $input, backtest_id: $backtest_id, bot_id: $bot_id) {
    _id
  }
}
    `;
export type CreateExecutionMutationFn = Apollo.MutationFunction<CreateExecutionMutation, CreateExecutionMutationVariables>;

/**
 * __useCreateExecutionMutation__
 *
 * To run a mutation, you first call `useCreateExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExecutionMutation, { data, loading, error }] = useCreateExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      backtest_id: // value for 'backtest_id'
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useCreateExecutionMutation(baseOptions?: Apollo.MutationHookOptions<CreateExecutionMutation, CreateExecutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExecutionMutation, CreateExecutionMutationVariables>(CreateExecutionDocument, options);
      }
export type CreateExecutionMutationHookResult = ReturnType<typeof useCreateExecutionMutation>;
export type CreateExecutionMutationResult = Apollo.MutationResult<CreateExecutionMutation>;
export type CreateExecutionMutationOptions = Apollo.BaseMutationOptions<CreateExecutionMutation, CreateExecutionMutationVariables>;
export const CreateStrategyDocument = gql`
    mutation CreateStrategy($file: Upload!, $input: CreateStrategyInput!) {
  createStrategy(file: $file, input: $input) {
    name
    _id
  }
}
    `;
export type CreateStrategyMutationFn = Apollo.MutationFunction<CreateStrategyMutation, CreateStrategyMutationVariables>;

/**
 * __useCreateStrategyMutation__
 *
 * To run a mutation, you first call `useCreateStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStrategyMutation, { data, loading, error }] = useCreateStrategyMutation({
 *   variables: {
 *      file: // value for 'file'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStrategyMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrategyMutation, CreateStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrategyMutation, CreateStrategyMutationVariables>(CreateStrategyDocument, options);
      }
export type CreateStrategyMutationHookResult = ReturnType<typeof useCreateStrategyMutation>;
export type CreateStrategyMutationResult = Apollo.MutationResult<CreateStrategyMutation>;
export type CreateStrategyMutationOptions = Apollo.BaseMutationOptions<CreateStrategyMutation, CreateStrategyMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    name
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserKeyDocument = gql`
    mutation CreateUserKey($input: KeyInput!) {
  createKey(input: $input) {
    _id
    name
    exchange
    publicKey
    privateKey
    passphrase
    user {
      _id
      email
    }
  }
}
    `;
export type CreateUserKeyMutationFn = Apollo.MutationFunction<CreateUserKeyMutation, CreateUserKeyMutationVariables>;

/**
 * __useCreateUserKeyMutation__
 *
 * To run a mutation, you first call `useCreateUserKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserKeyMutation, { data, loading, error }] = useCreateUserKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserKeyMutation, CreateUserKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserKeyMutation, CreateUserKeyMutationVariables>(CreateUserKeyDocument, options);
      }
export type CreateUserKeyMutationHookResult = ReturnType<typeof useCreateUserKeyMutation>;
export type CreateUserKeyMutationResult = Apollo.MutationResult<CreateUserKeyMutation>;
export type CreateUserKeyMutationOptions = Apollo.BaseMutationOptions<CreateUserKeyMutation, CreateUserKeyMutationVariables>;
export const BotsDocument = gql`
    query Bots {
  botsByUser {
    _id
    name
  }
}
    `;

/**
 * __useBotsQuery__
 *
 * To run a query within a React component, call `useBotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBotsQuery(baseOptions?: Apollo.QueryHookOptions<BotsQuery, BotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BotsQuery, BotsQueryVariables>(BotsDocument, options);
      }
export function useBotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BotsQuery, BotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BotsQuery, BotsQueryVariables>(BotsDocument, options);
        }
export type BotsQueryHookResult = ReturnType<typeof useBotsQuery>;
export type BotsLazyQueryHookResult = ReturnType<typeof useBotsLazyQuery>;
export type BotsQueryResult = Apollo.QueryResult<BotsQuery, BotsQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    _id
    email
    name
    profit
    language
    roles
    plan
    maximum_pairs
    last_build
    maximum_builds
    current_builds
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const StrategiesDocument = gql`
    query Strategies {
  strategies {
    _id
    name
    description
    productionVersion
    backtests {
      _id
      version
      exchange
    }
  }
}
    `;

/**
 * __useStrategiesQuery__
 *
 * To run a query within a React component, call `useStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<StrategiesQuery, StrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategiesQuery, StrategiesQueryVariables>(StrategiesDocument, options);
      }
export function useStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategiesQuery, StrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategiesQuery, StrategiesQueryVariables>(StrategiesDocument, options);
        }
export type StrategiesQueryHookResult = ReturnType<typeof useStrategiesQuery>;
export type StrategiesLazyQueryHookResult = ReturnType<typeof useStrategiesLazyQuery>;
export type StrategiesQueryResult = Apollo.QueryResult<StrategiesQuery, StrategiesQueryVariables>;
export const GetExchangeKeyDocument = gql`
    query GetExchangeKey {
  keys {
    _id
    name
    exchange
    publicKey
    privateKey
    passphrase
  }
}
    `;

/**
 * __useGetExchangeKeyQuery__
 *
 * To run a query within a React component, call `useGetExchangeKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExchangeKeyQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangeKeyQuery, GetExchangeKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeKeyQuery, GetExchangeKeyQueryVariables>(GetExchangeKeyDocument, options);
      }
export function useGetExchangeKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeKeyQuery, GetExchangeKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeKeyQuery, GetExchangeKeyQueryVariables>(GetExchangeKeyDocument, options);
        }
export type GetExchangeKeyQueryHookResult = ReturnType<typeof useGetExchangeKeyQuery>;
export type GetExchangeKeyLazyQueryHookResult = ReturnType<typeof useGetExchangeKeyLazyQuery>;
export type GetExchangeKeyQueryResult = Apollo.QueryResult<GetExchangeKeyQuery, GetExchangeKeyQueryVariables>;
export const GetKeysByExchangeDocument = gql`
    query GetKeysByExchange($exchange: String!) {
  keysByExchange(exchange: $exchange) {
    _id
    name
    publicKey
    privateKey
    passphrase
  }
}
    `;

/**
 * __useGetKeysByExchangeQuery__
 *
 * To run a query within a React component, call `useGetKeysByExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeysByExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeysByExchangeQuery({
 *   variables: {
 *      exchange: // value for 'exchange'
 *   },
 * });
 */
export function useGetKeysByExchangeQuery(baseOptions: Apollo.QueryHookOptions<GetKeysByExchangeQuery, GetKeysByExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeysByExchangeQuery, GetKeysByExchangeQueryVariables>(GetKeysByExchangeDocument, options);
      }
export function useGetKeysByExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeysByExchangeQuery, GetKeysByExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeysByExchangeQuery, GetKeysByExchangeQueryVariables>(GetKeysByExchangeDocument, options);
        }
export type GetKeysByExchangeQueryHookResult = ReturnType<typeof useGetKeysByExchangeQuery>;
export type GetKeysByExchangeLazyQueryHookResult = ReturnType<typeof useGetKeysByExchangeLazyQuery>;
export type GetKeysByExchangeQueryResult = Apollo.QueryResult<GetKeysByExchangeQuery, GetKeysByExchangeQueryVariables>;
export const GetOpenAiAnswerDocument = gql`
    query GetOpenAiAnswer($input: String!) {
  getAnswer(input: $input) {
    answer
  }
}
    `;

/**
 * __useGetOpenAiAnswerQuery__
 *
 * To run a query within a React component, call `useGetOpenAiAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAiAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAiAnswerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOpenAiAnswerQuery(baseOptions: Apollo.QueryHookOptions<GetOpenAiAnswerQuery, GetOpenAiAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenAiAnswerQuery, GetOpenAiAnswerQueryVariables>(GetOpenAiAnswerDocument, options);
      }
export function useGetOpenAiAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenAiAnswerQuery, GetOpenAiAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenAiAnswerQuery, GetOpenAiAnswerQueryVariables>(GetOpenAiAnswerDocument, options);
        }
export type GetOpenAiAnswerQueryHookResult = ReturnType<typeof useGetOpenAiAnswerQuery>;
export type GetOpenAiAnswerLazyQueryHookResult = ReturnType<typeof useGetOpenAiAnswerLazyQuery>;
export type GetOpenAiAnswerQueryResult = Apollo.QueryResult<GetOpenAiAnswerQuery, GetOpenAiAnswerQueryVariables>;
export const GetStatisticsDocument = gql`
    query GetStatistics($backtest_id: ObjectId!) {
  readStatistics(backtest_id: $backtest_id) {
    _id
    realized_profit
    avg_profit_per_trade
    avg_profit_per_trade_prct
    avg_position_size
    avg_profit_per_win_trade
    avg_profit_per_win_trade_prct
    avg_profit_per_loss_trade
    avg_profit_per_loss_trade_prct
    avg_long_profit_prct
    avg_short_profit_prct
    avg_holding_duration_h
    best_trade
    worse_trade
    cumulative_fees_paid
    nb_winning_trades
    nb_losing_trades
    total_nb_trades
    prc_winning_trade
    nb_long_positions
    nb_short_positions
    prc_exit_signals
    prc_max_holding
    prc_tp
    prc_sl
    best_day_profit
    worse_day_loss
    max_nb_days_underwater
    best_pair_perf
    best_pair_return
    worst_pair_perf
    worst_pair_return
    pair_with_most_positions
    pair_with_less_positions
    geometric_return
    annualized_std_deviation
    sharp_ratio
    downside_volatility
    sortino_ratio
    max_drawdown
    max_drawdown_start
    max_drawdown_end
    open_date
    profit_long
    profit_short
  }
}
    `;

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *      backtest_id: // value for 'backtest_id'
 *   },
 * });
 */
export function useGetStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
      }
export function useGetStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
        }
export type GetStatisticsQueryHookResult = ReturnType<typeof useGetStatisticsQuery>;
export type GetStatisticsLazyQueryHookResult = ReturnType<typeof useGetStatisticsLazyQuery>;
export type GetStatisticsQueryResult = Apollo.QueryResult<GetStatisticsQuery, GetStatisticsQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    _id
    name
    email
    roles
    plan
    maximum_pairs
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    user {
      name
      roles
      email
      profit
      language
      plan
      maximum_pairs
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const PingDocument = gql`
    query Ping {
  pingGodzilla {
    answer
  }
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const ReadBalanceDocument = gql`
    query ReadBalance($exchange: String!) {
  readUserBalance(exchange: $exchange) {
    exchange
    balance
  }
}
    `;

/**
 * __useReadBalanceQuery__
 *
 * To run a query within a React component, call `useReadBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalanceQuery({
 *   variables: {
 *      exchange: // value for 'exchange'
 *   },
 * });
 */
export function useReadBalanceQuery(baseOptions: Apollo.QueryHookOptions<ReadBalanceQuery, ReadBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalanceQuery, ReadBalanceQueryVariables>(ReadBalanceDocument, options);
      }
export function useReadBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalanceQuery, ReadBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalanceQuery, ReadBalanceQueryVariables>(ReadBalanceDocument, options);
        }
export type ReadBalanceQueryHookResult = ReturnType<typeof useReadBalanceQuery>;
export type ReadBalanceLazyQueryHookResult = ReturnType<typeof useReadBalanceLazyQuery>;
export type ReadBalanceQueryResult = Apollo.QueryResult<ReadBalanceQuery, ReadBalanceQueryVariables>;
export const ReadExchangesDocument = gql`
    query ReadExchanges {
  exchanges {
    _id
    name
    tradeablePairs
    historicalBacktest
    productionReady
    quoteAssets
  }
}
    `;

/**
 * __useReadExchangesQuery__
 *
 * To run a query within a React component, call `useReadExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadExchangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadExchangesQuery(baseOptions?: Apollo.QueryHookOptions<ReadExchangesQuery, ReadExchangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadExchangesQuery, ReadExchangesQueryVariables>(ReadExchangesDocument, options);
      }
export function useReadExchangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadExchangesQuery, ReadExchangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadExchangesQuery, ReadExchangesQueryVariables>(ReadExchangesDocument, options);
        }
export type ReadExchangesQueryHookResult = ReturnType<typeof useReadExchangesQuery>;
export type ReadExchangesLazyQueryHookResult = ReturnType<typeof useReadExchangesLazyQuery>;
export type ReadExchangesQueryResult = Apollo.QueryResult<ReadExchangesQuery, ReadExchangesQueryVariables>;
export const ReadKeysDocument = gql`
    query ReadKeys {
  keys {
    _id
    name
    exchange
    publicKey
    privateKey
    passphrase
  }
}
    `;

/**
 * __useReadKeysQuery__
 *
 * To run a query within a React component, call `useReadKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadKeysQuery(baseOptions?: Apollo.QueryHookOptions<ReadKeysQuery, ReadKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadKeysQuery, ReadKeysQueryVariables>(ReadKeysDocument, options);
      }
export function useReadKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadKeysQuery, ReadKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadKeysQuery, ReadKeysQueryVariables>(ReadKeysDocument, options);
        }
export type ReadKeysQueryHookResult = ReturnType<typeof useReadKeysQuery>;
export type ReadKeysLazyQueryHookResult = ReturnType<typeof useReadKeysLazyQuery>;
export type ReadKeysQueryResult = Apollo.QueryResult<ReadKeysQuery, ReadKeysQueryVariables>;
export const StartBotDocument = gql`
    mutation StartBot($bot_id: String!) {
  startBot(bot_id: $bot_id) {
    answer
  }
}
    `;
export type StartBotMutationFn = Apollo.MutationFunction<StartBotMutation, StartBotMutationVariables>;

/**
 * __useStartBotMutation__
 *
 * To run a mutation, you first call `useStartBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startBotMutation, { data, loading, error }] = useStartBotMutation({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useStartBotMutation(baseOptions?: Apollo.MutationHookOptions<StartBotMutation, StartBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartBotMutation, StartBotMutationVariables>(StartBotDocument, options);
      }
export type StartBotMutationHookResult = ReturnType<typeof useStartBotMutation>;
export type StartBotMutationResult = Apollo.MutationResult<StartBotMutation>;
export type StartBotMutationOptions = Apollo.BaseMutationOptions<StartBotMutation, StartBotMutationVariables>;
export const StartPaperBotDocument = gql`
    mutation StartPaperBot($bot_id: String!) {
  startPaperBot(bot_id: $bot_id) {
    answer
  }
}
    `;
export type StartPaperBotMutationFn = Apollo.MutationFunction<StartPaperBotMutation, StartPaperBotMutationVariables>;

/**
 * __useStartPaperBotMutation__
 *
 * To run a mutation, you first call `useStartPaperBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPaperBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPaperBotMutation, { data, loading, error }] = useStartPaperBotMutation({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useStartPaperBotMutation(baseOptions?: Apollo.MutationHookOptions<StartPaperBotMutation, StartPaperBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPaperBotMutation, StartPaperBotMutationVariables>(StartPaperBotDocument, options);
      }
export type StartPaperBotMutationHookResult = ReturnType<typeof useStartPaperBotMutation>;
export type StartPaperBotMutationResult = Apollo.MutationResult<StartPaperBotMutation>;
export type StartPaperBotMutationOptions = Apollo.BaseMutationOptions<StartPaperBotMutation, StartPaperBotMutationVariables>;
export const StopBotDocument = gql`
    mutation StopBot($bot_id: String!) {
  stopBot(bot_id: $bot_id) {
    answer
  }
}
    `;
export type StopBotMutationFn = Apollo.MutationFunction<StopBotMutation, StopBotMutationVariables>;

/**
 * __useStopBotMutation__
 *
 * To run a mutation, you first call `useStopBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopBotMutation, { data, loading, error }] = useStopBotMutation({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useStopBotMutation(baseOptions?: Apollo.MutationHookOptions<StopBotMutation, StopBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopBotMutation, StopBotMutationVariables>(StopBotDocument, options);
      }
export type StopBotMutationHookResult = ReturnType<typeof useStopBotMutation>;
export type StopBotMutationResult = Apollo.MutationResult<StopBotMutation>;
export type StopBotMutationOptions = Apollo.BaseMutationOptions<StopBotMutation, StopBotMutationVariables>;
export const StopPaperBotDocument = gql`
    mutation StopPaperBot($bot_id: String!) {
  stopPaperBot(bot_id: $bot_id) {
    answer
  }
}
    `;
export type StopPaperBotMutationFn = Apollo.MutationFunction<StopPaperBotMutation, StopPaperBotMutationVariables>;

/**
 * __useStopPaperBotMutation__
 *
 * To run a mutation, you first call `useStopPaperBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopPaperBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopPaperBotMutation, { data, loading, error }] = useStopPaperBotMutation({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useStopPaperBotMutation(baseOptions?: Apollo.MutationHookOptions<StopPaperBotMutation, StopPaperBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopPaperBotMutation, StopPaperBotMutationVariables>(StopPaperBotDocument, options);
      }
export type StopPaperBotMutationHookResult = ReturnType<typeof useStopPaperBotMutation>;
export type StopPaperBotMutationResult = Apollo.MutationResult<StopPaperBotMutation>;
export type StopPaperBotMutationOptions = Apollo.BaseMutationOptions<StopPaperBotMutation, StopPaperBotMutationVariables>;
export const StripeDocument = gql`
    mutation Stripe($plan: String!, $sales: Boolean!) {
  paymentIntentStripe(plan: $plan, sales: $sales) {
    clientSecret
  }
}
    `;
export type StripeMutationFn = Apollo.MutationFunction<StripeMutation, StripeMutationVariables>;

/**
 * __useStripeMutation__
 *
 * To run a mutation, you first call `useStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeMutation, { data, loading, error }] = useStripeMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *      sales: // value for 'sales'
 *   },
 * });
 */
export function useStripeMutation(baseOptions?: Apollo.MutationHookOptions<StripeMutation, StripeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeMutation, StripeMutationVariables>(StripeDocument, options);
      }
export type StripeMutationHookResult = ReturnType<typeof useStripeMutation>;
export type StripeMutationResult = Apollo.MutationResult<StripeMutation>;
export type StripeMutationOptions = Apollo.BaseMutationOptions<StripeMutation, StripeMutationVariables>;
export const UpdateCurrentBuildsDocument = gql`
    mutation UpdateCurrentBuilds($input: CurrentBuildsInput!) {
  updateCurrentBuilds(input: $input) {
    current_builds
  }
}
    `;
export type UpdateCurrentBuildsMutationFn = Apollo.MutationFunction<UpdateCurrentBuildsMutation, UpdateCurrentBuildsMutationVariables>;

/**
 * __useUpdateCurrentBuildsMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentBuildsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentBuildsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentBuildsMutation, { data, loading, error }] = useUpdateCurrentBuildsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentBuildsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentBuildsMutation, UpdateCurrentBuildsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentBuildsMutation, UpdateCurrentBuildsMutationVariables>(UpdateCurrentBuildsDocument, options);
      }
export type UpdateCurrentBuildsMutationHookResult = ReturnType<typeof useUpdateCurrentBuildsMutation>;
export type UpdateCurrentBuildsMutationResult = Apollo.MutationResult<UpdateCurrentBuildsMutation>;
export type UpdateCurrentBuildsMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentBuildsMutation, UpdateCurrentBuildsMutationVariables>;
export const UpdateLastBuildDocument = gql`
    mutation UpdateLastBuild($input: LastBuildInput!) {
  updateLastBuild(input: $input) {
    last_build
  }
}
    `;
export type UpdateLastBuildMutationFn = Apollo.MutationFunction<UpdateLastBuildMutation, UpdateLastBuildMutationVariables>;

/**
 * __useUpdateLastBuildMutation__
 *
 * To run a mutation, you first call `useUpdateLastBuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastBuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastBuildMutation, { data, loading, error }] = useUpdateLastBuildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLastBuildMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastBuildMutation, UpdateLastBuildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLastBuildMutation, UpdateLastBuildMutationVariables>(UpdateLastBuildDocument, options);
      }
export type UpdateLastBuildMutationHookResult = ReturnType<typeof useUpdateLastBuildMutation>;
export type UpdateLastBuildMutationResult = Apollo.MutationResult<UpdateLastBuildMutation>;
export type UpdateLastBuildMutationOptions = Apollo.BaseMutationOptions<UpdateLastBuildMutation, UpdateLastBuildMutationVariables>;
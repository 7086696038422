import { useState, useContext, createContext, ReactNode } from "react";
import { useApolloClient } from "@apollo/client";
import {
  useLoginMutation,
  useCurrentUserQuery,
  useCreateUserMutation,
} from "../generated/schema";
import { UserModel } from "../auth/models/UserModel";

type AuthProps = {
  pairs: any;
  userData: () => UserModel | null;
  user: any;
  error: string;
  isAuthenticated: boolean;
  isLogged: () => boolean;
  signIn: (email: any, password: any) => Promise<void>;
  signUp: (name: any, email: any, password: any) => Promise<void>;
  signOut: () => void;
};

const AuthContext = createContext<Partial<AuthProps>>({});

type pageProps = {
  children?: ReactNode;
}

export const AuthProvider: React.FC<pageProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Custom React hook to access the context
export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const client = useApolloClient();

  const [error, setError] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(
    localStorage.getItem("status") === "1"
  );

  // Load user data
  const { data } = useCurrentUserQuery({
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  });
  const user = data && data.currentUser;


  // Signing In
  const [signInMutation] = useLoginMutation();

  const signIn = async (email: string, password: string) => {
    try {
      const { data } = await signInMutation({
        variables: { input: { email, password } },
      });
      if (data?.login.token && data.login.user) {
        // Header key store
        localStorage.setItem("token", data.login.token);
        localStorage.setItem("status", "1");
        localStorage.setItem("user", JSON.stringify(data.login.user));

        // Prevent memory leak with unmonted component
        client.resetStore().then(() => {
          setAuthenticated(true); // Update auth state
        });
      } else {
        setError("Invalid Login");
      }
    } catch (err: any) {
      setError(err.message);
      console.log(err.message);
    }
  };

  // Sign up
  const [signUpMutation] = useCreateUserMutation();

  const signUp = async (name: string, email: string, password: string) => {
    try {
      await signUpMutation({
        variables: { input: { name, email, password } },
      });
    } catch (err: any) {
      setError(err.message);
      console.log(err.message);
    }
  };

  const userData = (): UserModel | null => {
    const data = localStorage.getItem("user");
    return JSON.parse(data ? data : '{"data":"no data"}');
  };

  const isLogged = (): boolean => {
    if (localStorage.getItem("status") === "1") setAuthenticated(true);
    setAuthenticated(false);
    return isAuthenticated;
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.setItem("status", "0");

    client.resetStore().then(() => {
      setAuthenticated(false);
    });
  };

  return {
    isLogged,
    userData,
    user,
    error,
    isAuthenticated,
    signIn,
    signUp,
    signOut,
  };
}

import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetHelpers";
type Props = {
  className?: string;
  title?: string;
  path: string;
  svgClassName?: string;
};

const KTSVG: React.FC<Props> = ({
  className = "",
  path,
  title,
  svgClassName = "mh-50px",
}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG
        src={toAbsoluteUrl(path)}
        title={title ? title : ""}
        className={svgClassName}
      />
    </span>
  );
};

export { KTSVG };



export const ICONS = {
    'one':"M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.58509 12C9.05586 12 9.4375 11.6184 9.4375 11.1476V5C9.4375 4.44772 8.98978 4 8.4375 4H8.38334C8.15845 4 7.94012 4.07581 7.76362 4.21518L5.9554 5.64303C5.6703 5.86815 5.62389 6.28274 5.85214 6.56534C6.07759 6.84446 6.48606 6.88926 6.76664 6.66564L7.18647 6.33105C7.29681 6.2435 7.49359 6.05928 7.77681 5.77839L7.74922 6.61012L7.73267 7.37073V11.1476C7.73267 11.6184 8.11431 12 8.58509 12Z",
    'cross':"M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.6282 10.6617C10.9519 10.3192 10.9519 9.78637 10.6282 9.46287L9.19982 7.99762L10.6663 6.53237C10.99 6.18985 10.99 5.65703 10.6663 5.33353C10.3235 5.01004 9.79021 5.01004 9.46645 5.33353L8 6.79878L6.53355 5.33353C6.19075 5.01004 5.65749 5.01004 5.33373 5.33353C5.00997 5.67606 5.00997 6.20888 5.33373 6.53237L6.80018 7.99762L5.33373 9.46287C5.00997 9.8054 5.00997 10.3382 5.33373 10.6617C5.48609 10.833 5.71463 10.9091 5.92412 10.9091C6.13361 10.9091 6.3431 10.833 6.51451 10.6617L7.98096 9.19646L9.4474 10.6617C9.59976 10.833 9.8283 10.9091 10.0378 10.9091C10.2473 10.9091 10.4568 10.833 10.6282 10.6617Z",
    'infinite':"M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM3.38721 7.99999C3.38721 8.87695 3.60854 9.57972 4.05121 10.1083C4.49388 10.6249 5.09963 10.8832 5.86848 10.8832C6.2296 10.8832 6.60237 10.781 6.98679 10.5768C7.38287 10.3726 7.72069 10.0122 8.00027 9.49563C8.2915 10.0122 8.6235 10.3726 8.99627 10.5768C9.3807 10.781 9.75347 10.8832 10.1146 10.8832C10.8834 10.8832 11.4892 10.6249 11.9319 10.1083C12.3862 9.57972 12.6133 8.87695 12.6133 7.99999C12.6133 7.14705 12.392 6.45629 11.9493 5.92771C11.5067 5.38712 10.8951 5.11682 10.1146 5.11682C9.75347 5.11682 9.3807 5.21893 8.99627 5.42316C8.6235 5.61537 8.2915 5.96976 8.00027 6.48632C7.72069 5.96976 7.38287 5.61537 6.98679 5.42316C6.60237 5.21893 6.2296 5.11682 5.86848 5.11682C5.08798 5.11682 4.4764 5.38712 4.03373 5.92771C3.60272 6.45629 3.38721 7.14705 3.38721 7.99999ZM6.79458 9.11721C6.515 9.32144 6.23542 9.42355 5.95584 9.42355C5.60637 9.42355 5.33262 9.2854 5.13458 9.00909C4.93654 8.73279 4.83753 8.39642 4.83753 7.99999C4.83753 7.60355 4.93654 7.26718 5.13458 6.99088C5.33262 6.71458 5.60637 6.57642 5.95584 6.57642C6.23542 6.57642 6.515 6.67253 6.79458 6.86474C7.07416 7.05695 7.30715 7.42936 7.49353 7.98197C7.30715 8.53457 7.07416 8.91299 6.79458 9.11721ZM10.8485 9.00909C10.6505 9.2854 10.3825 9.42355 10.0447 9.42355C9.76512 9.42355 9.48554 9.32144 9.20596 9.11721C8.93803 8.91299 8.70504 8.53457 8.50701 7.98197C8.70504 7.42936 8.93803 7.05695 9.20596 6.86474C9.48554 6.67253 9.76512 6.57642 10.0447 6.57642C10.3825 6.57642 10.6505 6.71458 10.8485 6.99088C11.0582 7.26718 11.163 7.60355 11.163 7.99999C11.163 8.38441 11.0582 8.72078 10.8485 9.00909Z",
    'check':"M 8 16 C 3.5816 16 0 12.4184 0 8 C 0 3.5816 3.5816 0 8 0 C 12.4184 0 16 3.5816 16 8 C 16 12.4184 12.4184 16 8 16 Z M 7.2024 11.2 L 12.2929 6.1088 C 12.6052 5.79642 12.6052 5.29 12.2928 4.97765 C 11.9805 4.66526 11.474 4.66528 11.1616 4.9777 L 7.2024 8.9376 L 5.5048 7.24 C 5.19243 6.92763 4.68597 6.92763 4.3736 7.24 C 4.06123 7.55237 4.06123 8.05883 4.3736 8.3712 L 7.2024 11.2 Z",
    'r-arrow':"M6.1746 11.8796a.5.5 0 01-.0542-.705L8.8414 8l-2.721-3.1746a.5.5 0 01.7592-.6508l3 3.5a.5.5 0 010 .6508l-3 3.5a.5.5 0 01-.705.0542z"
};
export const OFFERS = [
    {
        'label':'Basic',
        'price':'0',
        'sale':'0',
        'items': [{'description': 'example offer item 1', 'icon':ICONS.one, 'color':'#6b7b8b'},{'description': 'example offer item 2', 'icon':ICONS.infinite, 'color':'#00a59a'},{'description': 'example offer item 3', 'icon':ICONS.cross, 'color':'#ee7d8b'},{'description': 'example offer item 4', 'icon':ICONS.check, 'color':'#00a59a'}],
    },
    {
        'label':'Advanced',
        'price':'10',
        'sale':'8',
        'items': [{'description': 'example offer item 1', 'icon':ICONS.one, 'color':'#6b7b8b'},{'description': 'example offer item 2', 'icon':ICONS.infinite, 'color':'#00a59a'},{'description': 'example offer item 3', 'icon':ICONS.cross, 'color':'#ee7d8b'},{'description': 'example offer item 4', 'icon':ICONS.check, 'color':'#00a59a'}],
    },
    {
        'label':'Expert',
        'price':'15',
        'sale':'12',
        'items': [{'description': 'example offer item 1', 'icon':ICONS.one, 'color':'#6b7b8b'},{'description': 'example offer item 2', 'icon':ICONS.infinite, 'color':'#00a59a'},{'description': 'example offer item 3', 'icon':ICONS.cross, 'color':'#ee7d8b'},{'description': 'example offer item 4', 'icon':ICONS.check, 'color':'#00a59a'}],
    },
    {
        'label':'Researcher',
        'price':'20',
        'sale':'16',
        'items': [{'description': 'example offer item 1', 'icon':ICONS.one, 'color':'#6b7b8b'},{'description': 'example offer item 2', 'icon':ICONS.infinite, 'color':'#00a59a'},{'description': 'example offer item 3', 'icon':ICONS.cross, 'color':'#ee7d8b'},{'description': 'example offer item 4', 'icon':ICONS.check, 'color':'#00a59a'}],
    },
    {
        'label':'Scientist',
        'price':'25',
        'sale':'20',
        'items': [{'description': 'example offer item 1', 'icon':ICONS.one, 'color':'#6b7b8b'},{'description': 'example offer item 2', 'icon':ICONS.infinite, 'color':'#00a59a'},{'description': 'example offer item 3', 'icon':ICONS.cross, 'color':'#ee7d8b'},{'description': 'example offer item 4', 'icon':ICONS.check, 'color':'#00a59a'}],
    }
];

export const ERRORS = {
    "payment_down": "Oops...Sorry, the payment service seems to be currently unavailable, please try again later.",
    "support": " You can also contact our support for assistance."
}
import React, { useRef, useEffect } from "react";
import { AsideMenuMain } from "./AsideMenuMain";

type Props = {
  asidePrimaryDisplay: boolean;
};

const AsideMenu: React.FC<Props> = ({ asidePrimaryDisplay }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    if (!asidePrimaryDisplay) {
      scrollRef.current.setAttribute(
        "data-kt-scroll-dependencies",
        "#kt_aside_logo"
      );
    } else {
      scrollRef.current.removeAttribute("data-kt-scroll-dependencies");
    }
  }, [asidePrimaryDisplay]);
  return (
    <div
      className="menu-column menu-rounded menu-title-gray-700 menu-state-title-coinbase fw-bold fs-6"
      data-kt-menu="true"
    >
      <div
        ref={scrollRef}
        className=""
        id="kt_aside_menu_scroll"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_aside_wordspace"
      >
        <AsideMenuMain />
      </div>
    </div>
  );
};

export { AsideMenu };

import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client';


let uri_link = ""

if (window.location.href.includes('tuxn')){
  uri_link = 'https://api.novalabs.ai/graphql'
} else {
  uri_link = "http://localhost:8080/graphql"
}

console.log(`url_link : ${uri_link}`)

const createApolloClient = () => {
  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = localStorage.getItem('token')
    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const uploadLink = createUploadLink({
    uri: uri_link,
    credentials: 'include'
  });

  return new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache(),
  })
}

export default createApolloClient

import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { ThemeProvider } from "./layout/core";
import { Logout } from "./auth/Logout";
import { PrivateRoutes } from "./routes/PrivateRoutes";
import { AuthPage } from "./auth";
import { useAuth } from "./library/useAuth";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  let { isAuthenticated } = useAuth();

  return (
    <BrowserRouter basename={basename}>
      <ThemeProvider>
        <Routes>
          <Route path="logout" element={<Logout />} />
          {isAuthenticated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/overview" />} />
            </>
          ) : (
            <>
              <Route path="login/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { App };

import { Suspense, lazy } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { FallbackView } from "../template";
import { MasterLayout } from "../layout/MasterLayout";
import { DashboardWrapper } from "../pages/overview/OverviewWrapper";

const PrivateRoutes = () => {

  const BuilderWrapper = lazy(
    () => import("../pages/builder/BuilderWrapper")
  );

  const StrategiesWrapper = lazy(
    () => import("../pages/strategies/StrategiesWrapper")
  );

  const AccountWrapper = lazy(
    () => import("../pages/account/AccountWrapper")
  );

  const AcademyWrapper = lazy(
    () => import("../pages/academy/AcademyWrapper")
  );

  const SubscriptionWrapper = lazy(
    () => import("../pages/subscription/SubscriptionWrapper")
  );

  const SupportWrapper = lazy(
    () => import("../pages/support/SupportWrapper")
  );
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="login/*" element={<Navigate to="/overview" />} />

        <Route path="/overview" element={<DashboardWrapper />} />

        <Route
          path="/builder/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <BuilderWrapper />
            </Suspense>
          }
        />

        <Route
          path="/strategies/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <StrategiesWrapper />
            </Suspense>
          }
        /> 

        <Route
          path="/account/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <AccountWrapper />
            </Suspense>
          }
        /> 

        <Route
          path="/academy/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <AcademyWrapper />
            </Suspense>
          }
        /> 

        <Route
          path="/subscription/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <SubscriptionWrapper />
            </Suspense>
          }
        />

        <Route
          path="/support/*"
          element={
            <Suspense fallback={<FallbackView />}>
              <SupportWrapper />
            </Suspense>
          }
        /> 
            
            
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTheme } from "../core";

export function Footer() {
  const { classes } = useTheme();
  return (
    <div className={`footer py-4 d-flex flex-lg-column  bg-white`} id="kt_footer">
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a
            href="https://novalabs.ai/"
            className="text-gray-800 text-hover-coinbase text-decoration-none"
          >
            Novalabs.ai
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a href="#" className="menu-link ps-0 pe-2 text-decoration-none">
              Terms
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0 pe-2 text-decoration-none">
              Privacy Policy
            </a>
          </li>
     
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
}

import React from "react";
import { useAuth } from "../../../library/useAuth";
import { toAbsoluteUrl } from "../../../helpers";

const AsideProfile: React.FC = () => {
  const { userData } = useAuth();
  const user = userData && userData();

  return (
    <div className="profile">
      <div className="w-50 mx-auto">
        <div className="symbol symbol-100px symbol-circle pt-4">
          <img
            className="border border-1 border-primary"
            src={toAbsoluteUrl("/media/avatar/algo-ai.png")}
            alt="Avatar"
          />
        </div>
      </div>
      <div className="pt-4">
        <div className="text-center pb-12">
          <h3 className="fw-bolder text-gray-800 fs-2">{user?.name}</h3>
          <UserBadge roles={user?.roles} />
        </div>
      </div>
    </div>
  );
};

type TUserBadge = {
  roles: Array<string> | undefined;
};

const UserBadge: React.FC<TUserBadge> = ({ roles }) => {
  const role = roles && roles.pop();
  console.log(role);
  return (
    <>
      <span
        className={`badge badge-light-${role === "ADMIN" ? "danger" : "primary"
          }`}
      >
        {role ? role : "Member"}
      </span>
    </>
  );
};

export { AsideProfile };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTheme } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";
import { AsideProfile } from "./AsideProfile";
import {BsTwitter, BsDiscord, BsYoutube} from 'react-icons/bs'
export function AsideDefault() {
  const { config, classes } = useTheme();

  return (
      <div
      id="kt_aside"
      className={clsx("aside", "bg-light-grey", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width={
        config.aside.secondaryDisplay
          ? "{default:'200px', '300px': '250px'}"
          : "50px"
      }
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggler"
    >
      <div className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace text-center fixed-top " id="kt_aside_wordspace">
          <div className="aside-logo " id="kt_aside_logo">
            <Link to="/">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-default.png")}
                className="mh-50px"
              />
            </Link>
          </div>

          <AsideProfile />
          
          <AsideMenu asidePrimaryDisplay={config.aside.primaryDisplay} />

        </div>

        <div className="aside-workspace text-center fixed-bottom d-flex justify-content-center mb-4 align-items-center">
          <div className="col-3">
            <Link to="/">
              <BsTwitter size="25"/>
            </Link>
          </div>

          <div className="col-3">
            <Link to="/">
              <BsDiscord size="25"/>
            </Link>
          </div>

          <div className="col-3">
            <Link to="/">
              <BsYoutube size="25"/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';


const binanceUrl = `https://fapi.binance.com/fapi/v1/ticker/24hr?symbol=`;


type Props = {
    assetOne: String;
    assetTwo: String;
  };

const PriceStream: React.FC<Props> = ({assetOne, assetTwo}) => {

    const basicState = {
        "lastPrice": "",
        "priceChangePercent": "0"
    }

    const basicStyle = {
        "signe": "+",
        "colour": "text-success",
    }

    const [streamOne, setStreamOne] = useState(basicState);
    const [streamTwo, setStreamTwo] = useState(basicState);

    const[styleOne, setStyleOne] = useState(basicStyle);
    const[styleTwo, setStyleTwo] = useState(basicStyle);

    useEffect(() => {
        const getData = async () => {
            try {
              const responseOne = await axios.get(`${binanceUrl}${assetOne}`);
              const responseTwo = await axios.get(`${binanceUrl}${assetTwo}`);

              setStreamOne(responseOne.data);
              setStreamTwo(responseTwo.data);

              const deltaOne = parseFloat(responseOne.data.priceChangePercent);
              if (deltaOne >= 0){
                setStyleOne({
                    "signe": "+",
                    "colour": "text-success",
                })
              } else {
                setStyleOne({
                    "signe": "",
                    "colour": "text-danger",
                })
              }

              const deltaTwo = parseFloat(responseTwo.data.priceChangePercent);
              if (deltaTwo >= 0){
                setStyleTwo({
                    "signe": "+",
                    "colour": "text-success",
                })
              } else {
                setStyleTwo({
                    "signe": "",
                    "colour": "text-danger",
                })
              }
            } catch (error) {
              console.log(error);
            }
        };

        getData();
        const intervalId = setInterval(getData, 3000);
        return () => clearInterval(intervalId);
    }, [assetOne, assetTwo]);

    return (
        <div className='row '>
            <div className='col-1 col-md-4 mx-3'>
                <h6 className='text-center'>
                    {assetOne} 
                </h6>

                <h6 className="fs-10">
                    ${streamOne.lastPrice.slice(0, -3)}
                </h6>

                <h6 className={styleOne.colour}>
                    {styleTwo.signe}{streamOne.priceChangePercent.slice(0, -1)}%
                </h6>
            </div>

            <div className='col-2'>
                <h6 className='text-sm'>
                    {assetTwo} 
                </h6>

                <h6>
                ${streamTwo.lastPrice.slice(0, -3)}
                </h6>

                <h6 className={styleTwo.colour}>
                {styleTwo.signe}{streamTwo.priceChangePercent.slice(0, -1)}%
                </h6>
            </div>
        </div>
    );
};

export default PriceStream;
import { useEffect } from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { Outlet } from "react-router-dom";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider } from "./core";
import { MenuComponent } from "../assets/ts/components";

const MasterLayout = () => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderWrapper />
            <div className="d-flex flex-column flex-column-fluid">
              <Toolbar />
              <div
                className="content fs-6 d-flex flex-column-fluid"
                id="kt_content"
              >
                <Content>
                  <Outlet />
                </Content>
                <ScrollTop />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <MasterInit />
    </PageDataProvider>
  );
};

export { MasterLayout };

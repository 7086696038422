import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../library/useAuth";

export function Logout() {
  const { signOut } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    if (signOut) signOut();
    navigate("/");
  }, [signOut, navigate]);

  return <></>;
}

import { createRoot } from 'react-dom/client';

// Apollo
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "./library/apollo";

import { AuthProvider } from "./library/useAuth";

// App
import { App } from "./App";
import "./assets/sass/style.scss";

const { PUBLIC_URL } = process.env || "./";

/**
 * Initialize Apollo client
 */
const client = createApolloClient();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <ApolloProvider client={client}>
    <AuthProvider>
      <App basename={PUBLIC_URL} />
    </AuthProvider>
  </ApolloProvider>
);

import React from "react";

import { useAuth } from "../../../library/useAuth";
import PriceStream from './widget/PriceStream'
import CurrentBalance from "./widget/CurrentBalance"
import { OFFERS } from "../../../pages/subscription/data/Constants";
export function Header() {
  const { userData } = useAuth();
  const user = userData && userData();

  console.log(user)
  return (
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
      
      <div className= "fw-bold mx-4">
        <PriceStream assetOne='BTCUSDT' assetTwo='ETHUSDT'/>
      </div>
      
      <div className=" d-flex align-items-center justify-content-between text-muted fw-bold mx-5">
        
        <CurrentBalance />
        
        <div className= "text-muted fw-bold mx-5">
            <p> {user?.plan !== undefined ? OFFERS[user?.plan].label.toUpperCase() : ''} PLAN </p>
            <p> Bots Available : 1 </p>
            <p> Maximum Pairs : {user?.maximum_pairs} </p>
        </div>
      </div>
    </div>
  );
}
